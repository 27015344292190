<section class="section about" id='about'>
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>01.</span> {{"AboutMe.Title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor='let text of "AboutMe.Paragraphs" | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                        </p>
                        <ul class="skills-list" data-aos="fade-up">
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Python </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">C++ </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Django &amp; DRF </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Rust &amp; WASM</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">PyTorch </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Docker </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Active Learning </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Git </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Computer vision</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">CI / CD </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">TypeScript </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Angular </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Google Cloud Platform (GCP) </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">High performance computing</span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <!-- <div class="about-img-container">
                        <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")'  width="300" height="300" src="assets/images/me/mario.svg" alt="Git User">
                    </div> -->
                </div>

            </div>
        </div>
    </div>
</section>
