<app-header></app-header>
<section class="section about">
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h2 class="section">
                    Sampling using WebAssembly
                </h2>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p data-aos="fade-up" data-aos-duration="1000">
                            This demonstration shows the performance of WebAssembly to perform a computationally
                            demanding task. The presented use case
                            is sampling data based on their interdistances. The output is a list of indices of the sampled matrix columns.
                            To test this, follow these steps:
                        </p>
                        <ul data-aos="fade-up" class="section about">
                            <li class="about-description">Upload a '.csv' file with the data you want to sample.</li>
                            <li class="about-description">The CSV file should contain a matrix of floats of size NxM.</li>
                            <li class="about-description">Click on the <span class='n-section-title'>Perform Sampling</span> button.</li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-img-container">
                        <img width="300" height="300" src="assets/images/demos/filter-info.webp" alt="Git User">
                    </div>
                </div>

            </div>
        </div>
        <div class="file-upload-container" [class.dragover]="isDragOver" (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
            <p *ngIf="!file">Drag and drop your file here, or click to upload</p>
            <p *ngIf="file">File: {{ file.name }}</p>
            <input data-aos="fade-up" type="file" accept="text/csv" (change)="onFileSelected($event)" hidden #fileInput>
            <button type="button" (click)="fileInput.click()">Choose File</button>
        </div>
        <div class="perform-button-container">
            <button id="perform-sampling" class="btn btn-primary" (click)="performSampling()"
                [disabled]="!buttonActive">Perform
                sampling</button>
        </div>
        <div class="container" style="overflow: hidden;" *ngIf="this.sample_indices?.length! > 0">
            <p class="expand-vertical" id="sampling-results"> {{this.sample_indices}} </p>
        </div>
        <!-- <div *ngIf="this.sample_indices?.length! > 0">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Index</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let index of this.sample_indices; let i = index">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>{{ index }}</td>
                    </tr>
                </tbody>
            </table>
        </div> -->
    </div>
</section>