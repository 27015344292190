import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { AugmentedRealityComponent } from './augmented-reality/augmented-reality.component';
import { CoreSamplingWasm } from './core-sampling-wasm/core-sampling-wasm.component';
import { GeneralModule } from '../general/general.module';

const routes: Routes = [
  { path: 'augmented-reality', component: AugmentedRealityComponent },
  { path: 'sampling', component: CoreSamplingWasm }

];

@NgModule({
  declarations: [
    AugmentedRealityComponent,
    CoreSamplingWasm
  ],
  imports: [
    CommonModule,
    GeneralModule,
    RouterModule.forChild(routes)
  ]
})
export class DemosModule { }
