import { Component, OnInit } from '@angular/core';
import init, { add_one, perform_diversity_sampling } from '../../../../assets/wasm';


@Component({
  selector: 'app-contact',
  templateUrl: './core-sampling-wasm.component.html',
  styleUrls: ['./core-sampling-wasm.component.scss']
})
export class CoreSamplingWasm implements OnInit {

  supportedTypes = ['text/csv']
  actual_number = 0;
  csv_data!: any;
  csv_dimensions!: any;
  sample_indices!: any;
  buttonActive = false;

  constructor(
    //public analyticsService: AnalyticsService
  ) { }

  async ngOnInit(): Promise<void> {
    console.log(this.actual_number);
    try {
      await init('/assets/wasm/filtering_bg.wasm');
      console.log('Wasm module loaded');
    } catch (err) {
      console.error(err);
    } finally {
      console.log('Wasm module initialized');
    }
  }
  isDragOver = false;
  file: File | null = null;

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = true;
  }

  add_one_to_number(): void {
    this.actual_number = add_one(this.actual_number);
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
  }

  scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
        element.scrollIntoView({
            behavior: 'smooth', // Smooth scrolling animation
            block: 'start'    // Align the element to the center of the viewport
        });
    } else {
        console.warn(`Element with ID '${elementId}' not found.`);
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      if (this.supportedTypes.indexOf(event.dataTransfer.files[0].type) === -1) {
        console.error('Unsupported file type');
        return;
      }

      this.file = event.dataTransfer.files[0];
      console.log('Dropped file:', this.file);

      // Create a FileReader to read the file
      const reader = new FileReader();

      // Define the callback for when the file is read
      reader.onload = (e) => {
        const fileContent = e.target?.result as string;
        console.log('File content:', fileContent);

        // Process the CSV content if needed
        const rows = fileContent.split('\n').map(row => row.split(',').map(cell => {
          // Trim whitespace and convert to number if possible
          const trimmed = cell.trim();
          if (!isNaN(Number(trimmed))) {
            return Number(trimmed);
          } else if (trimmed !== '') {
            return trimmed;
          } else {
            throw new Error('Invalid or empty cell found in CSV');
          }
        }));
        console.log('Parsed CSV rows:', rows);
        this.csv_data = rows;
        this.csv_dimensions = {
          rows: rows!.length,
          columns: rows[0]!.length
        };
        this.buttonActive = true;

      };

      // Read the file as text
      reader.readAsText(this.file);
    }
  }

  performSampling(): void {
    const flattenedData = new Float32Array(this.csv_data?.flat());
    const dataSize = flattenedData.length;

    const embSize = this.csv_dimensions?.columns;
    const numSamples = this.csv_dimensions?.rows;
    const distanceVector = new Float32Array(dataSize);

    // if (embSize === undefined || numSamples === undefined || dataSize === undefined || dataSize === 0) {
    //   throw new Error('Invalid  CSV data');
    // }
    this.sample_indices = perform_diversity_sampling(flattenedData, embSize, numSamples, distanceVector);
    setTimeout(() => {
      this.scrollToElement('perform-sampling');
    }, 550);
    console.log("Sample indices:");
    console.log(this.sample_indices);
  }

  performSamplingDummyExample() {
    var generatedVector =  new
    Float32Array(
    [ 1.0,2.0,0.0,
      1.0,2.0,0.1,
      2.0,3.0,0.5,
      3.0,4.0,0.5
    ]);

    var distanceVector = new Float32Array([0.,0.,0.,0.]);

    let length = distanceVector.length;
    //let myWasmArrayPtr = instance.exports.allocateF32Array(length);
    //let myWasmArray = new Float32Array(memory.buffer, myWasmArrayPtr, length);
    //console.log(test_vec_output(5));
    console.log(perform_diversity_sampling(generatedVector,3,4,distanceVector));
  }

  show_csv_data(): void {
    console.log(this.csv_data);
    console.log(this.csv_dimensions);
  }
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      if (this.supportedTypes.indexOf(input.files[0].type) === -1) {
        console.error('Unsupported file type');
        return;
      }
     
      this.file = input.files[0];
      console.log('Selected file:', this.file);

      // Create a FileReader to read the file
      const reader = new FileReader();

      // Define the callback for when the file is read
      reader.onload = (e) => {
        const fileContent = e.target?.result as string;
        console.log('File content:', fileContent);
        // Process the CSV content if needed
        const rows = fileContent.split('\n').map(row => row.split(',').map(cell => {
          // Trim whitespace and convert to number if possible
          const trimmed = cell.trim();
          if (!isNaN(Number(trimmed))) {
            return Number(trimmed);
          } else if (trimmed !== '') {
            return trimmed;
          } else {
            throw new Error('Invalid or empty cell found in CSV');
          }
        }));
        this.csv_data = rows;
        this.csv_dimensions = {
          rows: rows!.length,
          columns: rows[0]!.length
        };
        this.buttonActive = true;
        console.log('Parsed CSV rows:', rows);
      };

      // Read the file as text
      reader.readAsText(this.file);
    }
  }

}
