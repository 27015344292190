import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GridHelper } from 'three';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { MapControls } from 'three/examples/jsm/controls/MapControls.js';


@Component({
  selector: 'app-contact',
  templateUrl: './augmented-reality.component.html',
  styleUrls: ['./augmented-reality.component.scss']
})
export class AugmentedRealityComponent  implements AfterViewInit {
  ngAfterViewInit() {
    this.setupVideoStream();
    this.setupThreeJS();
  }

  setupVideoStream() {
    const video = document.getElementById('video') as HTMLVideoElement;

    navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
      video.srcObject = stream;
    }).catch((error) => {
      console.error('Error accessing the camera', error);
    });
  }

  setupThreeJS() {
    const canvas = document.getElementById('three-canvas') as HTMLCanvasElement;

    // Create scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ canvas, alpha: true }); // Alpha for transparency
    renderer.setClearColor( 0x000000, 0 ); // the default
    renderer.setSize(window.innerWidth, window.innerHeight);

    // Add a cube to the scene
    const geometry = new THREE.BoxGeometry(10, 10, 10);
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    const cube = new THREE.Mesh(geometry, material);
    scene.add(cube);

    camera.position.z = 10;

    // Add OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // Smooth motion
    controls.dampingFactor = 0.05;
    controls.screenSpacePanning = false; // Prevent camera panning on orbit
    controls.enablePan = true;
    controls.enableDamping = true;
    controls.target.set(0, 0, 0);

    // controls.minPolarAngle = Math.PI; // Limit downward rotation
    // controls.maxPolarAngle = Math.PI / 2; // Limit upward rotation

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      // Update controls
      controls.update();

      // Render the scene
      renderer.render(scene, camera);
    };
    animate();

    // Handle window resize
    window.addEventListener('resize', () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    });
  }

  
}
